/** @format */

@font-face {
  font-family: "MyHeboFont";
  src: url("../public/Heebo-Bold.ttf");
}

@font-face {
  font-family: "MyOwnFont";
  src: url("../public/PublicSans-Regular.ttf");
}
@font-face {
  font-family: "MyInterFont";
  src: url("../public/Inter-Regular.ttf");
}
.slick-slide {
  transition: filter 0.5s ease;
}

.slick-center .slick-slide {
  filter: none;
}

.slick-slide:not(.slick-center) {
  filter: blur(1px);
}

/* Styling for all dots */
.slick-dots li button {
  font-size: 0; /* Hides the default inner content */
  line-height: 0; /* Removes extra height */
  width: 10px; /* Dot width */
  height: 10px; /* Dot height */
  padding: 0;
  border: none; /* Removes any default border */
  border-radius: 50%; /* Makes the dot round */
  background: #cccccc; /* Inactive dot color */
  display: block; /* Ensures the width and height are respected */
  outline: none; /* Removes focus outline */
  transition: background-color 0.3s; /* Smooth transition for color change */
}

/* Styling for the active dot */
.slick-dots li.slick-active button {
  background: #0066ff; /* Active dot color */
  width: 12px; /* Larger width for active dot */
  height: 12px; /* Larger height for active dot */
}
