/** @format */

.outercontainer {
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  text-align: center;
  padding: 5% 2.5%;
  margin-top: 80px;
}

.container {
  background-color: #ffffff;
}

.title {
  font-size: 48px;
  margin-bottom: 10px;
  color: #333;
}
@font-face {
  font-family: "MyCustomFont";
  src: url("../public/PublicSans-Regular.ttf");
}

.subtitle {
  font-size: 24px;
  margin-bottom: 30px;
  color: #555;
}

.logo {
  max-width: 250px;
  display: block;
  margin: 0 auto 20px auto;
}

.cta-text {
  text-align: center;
  margin-bottom: 15px; /* Adjust for spacing below the text */
}
/* .download-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  gap: 20px; 
}
.download-buttons .btn {
  display: inline-flex;
  overflow: visible; 
  height: 100px; 
  width: auto;
  border-radius: 8px; 
  transition: transform 0.3s;
} */

/* .btn img {
  object-fit: contain; 
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.3s;
  border-radius: 8px;
} */

/* Media query for portrait mode */
@media screen and (orientation: portrait) {
  .btn img {
    width: 100%; /* Make sure it doesn't exceed screen width */
    height: auto; /* Adjust the height automatically based on image's aspect ratio and screen width */
  }
}
.contact {
  margin-top: 20px;
  font-size: 1.2em;
}

.contact a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.contact a:hover {
  color: #007bff; /* Adjust this to your brand color or preferred hover color */
}
.social-links {
  margin-top: 20px; /* Adjust as needed for spacing above the icons */
  display: flex;
  justify-content: center;
  gap: 15px; /* Adjust for spacing between icons */
}

.social-links a {
  display: inline-block;
  overflow: hidden;
  height: 25px; /* Reduced size of the icon containers */
  width: 25px; /* Reduced size of the icon containers */
  transition: transform 0.3s;
}

.social-links img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.3s;
}

.social-links a:hover img {
  transform: scale(1.1); /* Slight zoom effect on hover */
}
